import {useHistory} from "react-router";
import Page from "../../components/page/Page";
import Button from "../../components/button";
import {ROUTE_HOME} from "../../routes";
import logo from "../../assets/images/Logo_HHSW.svg"
import styles from "./Start.module.scss"
import uiLabels from "../../utils/uiLabels";
import Room from "../../scenes/room/Room";

function Start() {
  const history = useHistory();

  function onClick() {
    history.push(ROUTE_HOME);
  }

  return (
    <Page>
      <div className={styles.character}>
        <img src={logo} className={styles.logo} alt={'logo'}/>
        <Room yPos={-4}/>
      </div>
      <div className={styles.footer}>
        <h1>{uiLabels('intro.title')}</h1>
        <h2>{uiLabels('intro.subtitle')}</h2>
        <Button
          fullWidth={true}
          onClick={onClick}>
          {uiLabels('intro.cta')}
        </Button>
      </div>
    </Page>
  )
}

export default Start
