import {useControl} from "react-three-gui";

function Lights() {
  const x = useControl('x', { type: 'number', value: -114, min: -150, max: 150, group: "DirectionalLight"});
  const y = useControl('y', { type: 'number', value: 113, min: -150, max: 150, group: "DirectionalLight" });
  const z = useControl('z', { type: 'number', value: 23, min: -150, max: 150, group: "DirectionalLight" });
  const intensity = useControl('intensity', { type: 'number', value: 1, max: 5, group: "DirectionalLight" });
  const decay = useControl('decay', { type: 'number', value: 2, max: 15, group: "DirectionalLight" });
  const ambientIntensity = useControl('intensity', { type: 'number', value: 2, max: 15, group: "AmbientLight" });
  return (
    <>
      <ambientLight
        color='#656565'
        intensity={ambientIntensity}
      />
      <directionalLight
        color="#FFFFFF"
        intensity={intensity}
        decay={decay}
        position={[x, y, z]}
        scale={[1, 1, 1]}
        shadow-bias={-0.0006}
        castShadow
        shadow-mapSize-height={2048}
        shadow-mapSize-width={2048}
        shadow-camera-fov={130}
        shadow-camera-near={140}
        shadow-camera-far={185}
        shadow-camera-left={-40}
        shadow-camera-right={40}
        shadow-camera-top={30}
        shadow-camera-bottom={-30}
      />
    </>
  );
}

export default Lights;
