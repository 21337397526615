import Page from "../../components/page/Page";
import {ROUTE_HOME} from "../../routes";
import {useHistory} from "react-router";
import useWorkAreaScoreList from "../../hooks/useWorkAreaScoreList";
import {useSelector} from "react-redux";
import {getSelectedSkills} from "../../store/reducers";
import styles from "./Matches.module.scss";
import Popup from "../../components/popup/Popup";
import {useState} from "react";
import WorkAreaDetail from "../../components/workAreaDetail/workAreaDetail";
import IconButton from "../../components/iconButton/IconButton";
import uiLabels from "../../utils/uiLabels";
import MatchItem from "../../components/matchItem/MatchItem";

function Matches() {

  const [openPopup, setOpenPopup] = useState(false);
  const [selectedWorkArea, setSelectedWorkArea] = useState(false);

  const history = useHistory();
  const selectedSkillItems = useSelector(getSelectedSkills);
  const scoreList = useWorkAreaScoreList(selectedSkillItems);

  function goBack() {
    history.push(ROUTE_HOME);
  }
  function selectWorkArea(area) {
    setSelectedWorkArea(area[0]);
    setOpenPopup(true);
  }

  return (
    <>
      <Page classes={styles.page}>
        <div className={styles.header}>
          <IconButton click={goBack} type={'back'}/>
        </div>
        <div className={styles.body}>
          <h1>{uiLabels('matches.title')}</h1>
          <p>{uiLabels('matches.body')}</p>
          <h1>{uiLabels('matches.top.title')}</h1>
          {scoreList
            .map(score => <MatchItem arrow={true} key={score[0]} match={score} click={() => selectWorkArea(score)} />)
            .slice(0,3)}
          <h1>{uiLabels('matches.other.title')}</h1>
          {scoreList
            .slice(4, 99)
            .filter(score => score[1] !== 0)
            .map(score => <MatchItem arrow={true} key={score[0]} match={score} click={() => selectWorkArea(score)}/>)
          }
          <h1>{uiLabels('matches.no.title')}</h1>
          {scoreList
            .slice(4, 99)
            .filter(score => score[1] === 0)
            .map(score => <MatchItem arrow={true} key={score[0]} match={score} click={() => selectWorkArea(score)}/>)
          }
        </div>
      </Page>
      {openPopup && (
        <Popup>
          <div className={styles.header}>
            <IconButton click={()=> setOpenPopup(false)} type={'close'}/>
          </div>
          <div className={styles.body}>
            <WorkAreaDetail workAreaKey={selectedWorkArea} skills={selectedSkillItems}/>
          </div>
        </Popup>
      )}
    </>
  )
}

export default Matches
