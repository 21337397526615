import {combineReducers} from "redux";
import app, * as fromApp from './app';

export const getSelectedSkills = state => fromApp.getSkills(state.app);

const store = combineReducers({
  app,
});

export default store;
