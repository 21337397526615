
export const ROOM_PROP_ondernemerschap = 'Prop_Ondernemerschap';
export const ROOM_PROP_hierachisch = 'Prop_Hiërarchische_organisatie';
export const ROOM_PROP_zelfstandigheid = 'Prop_Zelfstandigheid';
export const ROOM_PROP_verzorgend = 'Prop_Verzorgende_taken';
export const ROOM_PROP_sturend = 'Prop_Leidinggevende_vaardigheden';
export const ROOM_PROP_coachend = 'Prop_Coachende_vaardigheden';
export const ROOM_PROP_formeel = 'Prop_Formele_organisatie';
export const ROOM_PROP_informeel = 'Prop_Informele_organisatie';
export const ROOM_PROP_begeleiden_groep = 'Prop_Begeleiden_van_groepen';
export const ROOM_PROP_begeleiden_individueel = 'Prop_Begeleiden_van_individuen';
export const ROOM_PROP_creatief = 'Prop_Creativiteit';
export const ROOM_PROP_belangen = 'Prop_Belangen_behartigen';

export const skillPropsArray = [
  ROOM_PROP_ondernemerschap,
  ROOM_PROP_hierachisch,
  ROOM_PROP_zelfstandigheid,
  ROOM_PROP_verzorgend,
  ROOM_PROP_sturend,
  ROOM_PROP_coachend,
  ROOM_PROP_formeel,
  ROOM_PROP_informeel,
  ROOM_PROP_begeleiden_groep,
  ROOM_PROP_begeleiden_individueel,
  ROOM_PROP_creatief,
  ROOM_PROP_belangen
];

export const skillPropsMap = {
  ondernemerschap: ROOM_PROP_ondernemerschap,
  hierachisch: ROOM_PROP_hierachisch,
  zelfstandigheid:ROOM_PROP_zelfstandigheid,
  verzorgend:ROOM_PROP_verzorgend,
  sturend:ROOM_PROP_sturend,
  coachend:ROOM_PROP_coachend,
  formeel:ROOM_PROP_formeel,
  informeel:ROOM_PROP_informeel,
  begeleiden_groep:ROOM_PROP_begeleiden_groep,
  begeleiden_individueel:ROOM_PROP_begeleiden_individueel,
  creatief:ROOM_PROP_creatief,
  belangen:ROOM_PROP_belangen
};
