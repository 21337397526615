import {useEffect, useState} from "react";
import {easeSinInOut} from "d3-ease";
import {useFrame} from "@react-three/fiber";
import {useSpring} from "@react-spring/three";

export default function usePopUpEffect(objects, callback) {
  const [{ scale }, api] = useSpring(() => ({ scale: [1, 1, 1]}));
  const [playAnimation, setPlayAnimation] = useState(false);

  useEffect(() => {
    function startAnimation() {
      api.start({
        scale: [1.5, 1.5, 1.5],
        config: { duration: 300, easing: easeSinInOut },
        onRest: () => {
          api.start({
            scale: [1, 1, 1],
            config: { duration: 300, easing: easeSinInOut },
            onRest: () => {
              setPlayAnimation(false);
              if(callback) callback();
            }
          });
        }
      })
    }
    if(objects.length > 0) {
      startAnimation();
      setPlayAnimation(true);
    }
  }, [api, objects, callback])

  useFrame(() => {
    if(objects.length > 0 && playAnimation) {
      const currentScale = scale.get();
      for(let i=0; i < objects.length; i++) {
        objects[i].scale.x = currentScale[0];
        objects[i].scale.y = currentScale[1];
        objects[i].scale.z = currentScale[2];
      }
    }
  })
}
