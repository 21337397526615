import React from 'react';
import cn from 'classnames';
import styles from './Page.module.scss';

const Page = ({ children, classes }) => (
  <div className={cn(styles.component, classes)}>
    {children}
  </div>
);

export default Page;
