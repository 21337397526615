import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

import rootReducer from './reducers';

const persistConfig = {
  key: 'haagse_hogeschool_metro',
  storage: storageSession,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)


const storeCreator = () => {
  let store = createStore(
    persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  let persistor = persistStore(store);
  return { store, persistor }
}

export default storeCreator;
