import React, { Suspense } from "react"
import Lights from "./components/Lights";
import Camera from "./components/Camera";
import RoomModel from "./models/RoomModel";
import SceneWrapper from "./components/SceneWrapper";

import {useSelector} from "react-redux";
import {getSelectedSkills} from "../../store/reducers";

function Room({yPos, playAnimations}) {
  const selectedSkillItems = useSelector(getSelectedSkills);

  return (
    <>
      <SceneWrapper>
        <Camera />
        <Lights/>
        <Suspense fallback={null}>
          <RoomModel objects={selectedSkillItems} yPos={yPos} play={playAnimations}/>
        </Suspense>
      </SceneWrapper>
    </>
  );
}

export default Room;
