import {useEffect, useState} from 'react';
import scores from "../content/scores";
import workareaContent from "../content/workAreas";

function useWorkAreaScoreList(selectedSkillItems) {
  const [sortedSet, setSortedList] = useState([]);
  const [workareas, setWorkareas] = useState([]);

  useEffect(()=>{
    const workAreaArray = workareaContent.map(discipline => discipline.key);
    setWorkareas(workAreaArray);
  }, []);

  useEffect(()=>{
    if(selectedSkillItems) {
      const selectedSkills = scores.filter(score => {
        return selectedSkillItems.find(item => item === score.key);
      });
      const scoreSet = {};
      selectedSkills.map(skill => {
        workareas.map(workarea => {
          if(!scoreSet[workarea]) { scoreSet[workarea] = 0 }
          if(skill.scores[workarea]) {
            scoreSet[workarea] = scoreSet[workarea] + skill.scores[workarea];
          }
          return workarea;
        });
        return skill;
      });
      const workareaKeys = Object.entries(scoreSet);
      const sortedSet =  workareaKeys.sort((a, b) => b[1] - a[1]);
      sortedSet.map(item => {
        const workArea = workareaContent.find(workArea => (workArea.key === item[0]));
        item.push(workArea?.content?.title);
        return item;
      })
      setSortedList(sortedSet);
    }
  }, [selectedSkillItems, workareas]);

  return sortedSet;
}

export default useWorkAreaScoreList;
