import Start from "../pages/start/Start";
import Home from "../pages/home/Home";
import Info from "../pages/info/Info";
import Character from "../pages/character/Character";
import Matches from "../pages/matches/Matches";

export const ROUTE_START = "/start";
export const ROUTE_HOME = "/home";
export const ROUTE_INFO = "/info";
export const ROUTE_CHARACTER = "/character";
export const ROUTE_MATCHES = "/matches";

const routes = [
  {
    path: ROUTE_START,
    component: Start,
    exact: true,
  },
  {
    path: ROUTE_HOME,
    component: Home,
  },
  {
    path: ROUTE_INFO,
    component: Info,
  },
  {
    path: ROUTE_CHARACTER,
    component: Character,
  },
  {
    path: ROUTE_MATCHES,
    component: Matches,
  },
];

export default routes;
