import React from "react";
import {Canvas} from "@react-three/fiber";
import styles from './SceneWrapper.module.scss';
import {VSMShadowMap} from "three"

// const WrappedCanvas = withControls(Canvas)

function SceneWrapper({ children }) {
  return (
    <div className={styles.canvasContainer}>
      <Canvas
        shadows={{
          type: VSMShadowMap,
        }}
        dpr={[1, 2]}
        performance={{ min: 0.5 }}
        gl={{ alpha: true, antialias: window.devicePixelRatio < 2 }}
      >
        {children}
      </Canvas>
    </div>
  );
}

export default SceneWrapper;
