import cn from 'classnames';
import {useHistory} from "react-router";

import Page from "../../components/page/Page";
import scores from "../../content/scores";

import styles from './Home.module.scss';
import {ROUTE_MATCHES} from "../../routes";
import {useDispatch, useSelector} from "react-redux";
import {toggleSkill} from "../../store/actions/app";
import {getSelectedSkills} from "../../store/reducers";
import useWorkAreaScoreList from "../../hooks/useWorkAreaScoreList";
import Button from "../../components/button";
import uiLabels from "../../utils/uiLabels";
import {useEffect, useRef, useState} from "react";
import MatchItem from "../../components/matchItem/MatchItem";
import ctaIcon from "../../assets/images/Middel 6.svg"
import Room from "../../scenes/room/Room";
import Popup from "../../components/popup/Popup";
import IconButton from "../../components/iconButton/IconButton";
import InfoButton from "../../assets/images/Button_Info.svg";
import ZoomButton from "../../assets/images/Button_Zoom.svg"

function Home() {

  const selectedSkillItems = useSelector(getSelectedSkills);
  const scoreList = useWorkAreaScoreList(selectedSkillItems);
  const scrollRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [openInfoPopup, setOpenInfoPopup] = useState(false);
  const [openRoomPopup, setOpenRoomPopup] = useState(false);

  function toggleItem(key) {
    dispatch(toggleSkill(key))
  }

  function closePopup() {
    setOpenInfoPopup(false);
    setOpenRoomPopup(false);
  }

  function onScoreClick() {
    if(scoreList.length === 0) return false;
    history.push(ROUTE_MATCHES);
  }

  function resetSkills() {
    selectedSkillItems.map(key => {
      dispatch(toggleSkill(key));
      return key;
    });
  }

  function randomSkills() {
    const shuffled = [...scores].sort(() => 0.5 - Math.random());
    const selected = shuffled.slice(0, 4);

    selected.map(item => {
      dispatch(toggleSkill(item.key));
      return item;
    });
  }

  useEffect(function () {
    if (scrollRef.current) {
      scrollRef.current.scrollTo( 0, scrollRef.current.scrollHeight/2 );
    }
  }, [scrollRef])


  return (
    <>
    <Page >
      <div className={styles.header}>
        <img src={InfoButton} onClick={() => setOpenInfoPopup(true)} className={styles.infoButton} alt={'info'} />
        <img src={ZoomButton} onClick={() => setOpenRoomPopup(true)} className={styles.zoomButton} alt={'zoom'} />

        <div className={styles.characterContainer}>
          {!openRoomPopup && <Room yPos={-2.5} playAnimations={true}/> }
        </div>
        <ul className={styles.scoreContainer}>
          <h1>{uiLabels('home.title')}</h1>
          <MatchItem match={scoreList?.[0]}/>
          <MatchItem match={scoreList?.[1]}/>
          <MatchItem match={scoreList?.[3]}/>
          <div className={cn(styles.cta, {[styles.disabled]: scoreList.length === 0})} onClick={onScoreClick}>
            <span>{uiLabels('home.cta')}</span>
            <img src={ctaIcon} alt={''} />
          </div>
        </ul>
      </div>
      <p className={styles.instructions}>{uiLabels('home.info')}</p>
      <div className={styles.body}>
        <div className={styles.scrollContainer} ref={scrollRef}>
          {scores.map(item => {
            const skillClasses = cn(styles.skillItem, { [styles.selected]: selectedSkillItems?.find(key => key === item.key), [styles.disabled]: selectedSkillItems.length === 4 && !selectedSkillItems?.find(key => key === item.key)})
            return (
              <div key={item.key} className={skillClasses} onClick={()=>toggleItem(item.key)}>{item.label}</div>
            )
          })}
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.footerColumn}>
          <Button fullWidth={true} onClick={resetSkills}>{uiLabels('home.button.reset')}</Button>
        </div>
        <div className={styles.footerColumn}>
          <Button disabled={selectedSkillItems.length > 0} fullWidth={true} onClick={randomSkills}>{uiLabels('home.button.random')}</Button>
        </div>
      </div>
    </Page>
    {(openInfoPopup || openRoomPopup) && (
      <Popup classes={cn(styles.popUp, {[styles.roomPopUp]: openRoomPopup})}>
        <div className={styles.popUpHeader}>
          <IconButton click={closePopup} type={'close'}/>
        </div>
        {openInfoPopup && (
          <div className={styles.popUpBody}>
            <h1>{uiLabels('info.title')}</h1>
            <p>{uiLabels('info.body')}</p>
          </div>
        )}
        {openRoomPopup && (
          <Room yPos={-2.5} playAnimations={true}/>
        )}
      </Popup>
    )}
  </>
  )
}

export default Home
