import React from 'react';
import styles from './MatchItem.module.scss';
import cn from 'classnames';

import YellowStar from "../../assets/images/Star_Yellow_Score.svg"
import RedArrow from "../../assets/images/Middel 6.svg"

function MatchItem({ match, click, arrow }) {


  return (
    <div className={cn(styles.container, {[styles.empty]: !match, [styles.clickable]: !!click})} onClick={click}>
      {match && <span className={styles.label}>{match[2]}</span>}
      {arrow && <img className={styles.arrow} src={RedArrow} alt={'star'} />}
      <div className={styles.score}>
        {match && (
          <>
            <img className={styles.star} src={YellowStar} alt={'star'} />
            <span>{match && match[1]}</span>
          </>
        )}
      </div>
    </div>
  )
};

export default MatchItem;
