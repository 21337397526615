import React from 'react';
import cn from 'classnames';
import styles from './Popup.module.scss';

const Popup = ({ children, classes }) => (
  <div className={styles.container}>
    <div className={cn(styles.component, classes)}>
      {children}
    </div>
  </div>
);

export default Popup;
