import {SCORES_TOGGLE_SKILL} from "../constants/actionTypes";

const MAX_SELECTED_SKILLS = 4;

const initialState = {
  skills: [],
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case SCORES_TOGGLE_SKILL:
      const findIndex = state.skills.findIndex((element) => element === action.payload);
      const result = (findIndex === -1)
        ? [...state.skills.concat([action.payload])]
        : state.skills.filter((element) => element !== action.payload);

      if(result.length > MAX_SELECTED_SKILLS) {
        return state;
      }
      return { ...state, skills: result };
    default:
      return state;
  }
};

export const getSkills = state => state.skills;

export default app;
