import routes from "./routes/";
import {
  BrowserRouter as Router,
  Switch,
  Route, Redirect
} from "react-router-dom";
import styles from './App.module.scss';
import createStore from './store'
import {Provider} from 'react-redux'

function App() {
  const { store } = createStore();

  return (
    <div className={styles.App}>
      <Provider store={store}>
        <Router basename={process.env.REACT_APP_BASENAME}>
          <Switch>
            {routes.map((route, i) => (
              <Route
                key={i}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            ))}
            <Route exact path="/">
              <Redirect to="/start" />
            </Route>
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
