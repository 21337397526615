import cn from 'classnames';

import styles from './Button.module.scss';

export default function Button({
                                 onClick,
                                 href,
                                 size = 'medium',
                                 children,
                                 hasBorder = false,
                                 fullWidth = false,
                                 active = false,
                                 disabled = false,
                                 ...props
}) {
  const buttonClassnames = cn(styles.button, {
    [styles.small]: size === 'small',
    [styles.hasBorder]: hasBorder,
    [styles.fullWidth]: fullWidth,
    [styles.active]: active,
  });

  if (href) {
    return (
      <a
        href={href}
        className={buttonClassnames}
        disabled={disabled}
        {...props}
      >
        <span className={styles.buttonContent}>
          {children}
        </span>
      </a>
    )
  }


  return (
    <button
      onClick={onClick}
      className={buttonClassnames}
      disabled={disabled}
      {...props}
    >
      <span className={styles.buttonContent}>
        {children}
      </span>
    </button>
  )
}
