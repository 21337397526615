import labels from "../content/language";

function uiLabels(key) {
  const label = labels.find(item => item.key === key);
  if(label) return label.value;

  return key;
}

export default uiLabels;
