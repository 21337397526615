import {useEffect, useState} from 'react';
import scores from "../content/scores";

function useScoresByWorkArea(workArea, skills) {
  const [sortedSet, setSortedList] = useState([]);


  useEffect(()=>{
    const workAreaScores = scores.map((skill) => {
      return {
        skill: skill?.key,
        label: skill?.label,
        score: skill?.scores[workArea],
        selected: !!skills?.find(item => item === skill?.key)
      };
    })
    const sortedSet =  workAreaScores.sort((a, b) => b.score - a.score);
    setSortedList(sortedSet);

  }, [workArea, skills]);

  return sortedSet;
}

export default useScoresByWorkArea;
