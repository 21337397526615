import React, {useEffect, useRef, useState} from 'react'
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import {skillPropsMap, skillPropsArray} from '../constants/skillItems'
import { useAnimations } from '@react-three/drei';
import usePopUpEffect from "../animations/usePopUpEffect";
import usePopOutEffect from "../animations/usePopOutEffect";
// import {LoopOnce, TextureLoader} from "three";
import {LoopOnce} from "three";

function RoomModel({ objects, yPos, play }) {
  const gltfProps = useLoader(GLTFLoader, '/StudieKamer_HH_OnlyProps.glb');
  const gltfRoom = useLoader(GLTFLoader, '/StudieKamer_HH_OnlyRoom.glb');
  // const texture = new TextureLoader().load( "/Mat_Room_Mixed_AO.png" );

  const [initialised, setInitialised] = useState(false);

  const [showProps, setShowProps] = useState([]);
  const [hideProps, setHideProps] = useState([]);

  const props = useRef();
  const room = useRef();

  const propAnimations = useAnimations(gltfProps.animations, props);
  const propActions = propAnimations.actions;

  const roomAnimations = useAnimations(gltfRoom.animations, room);
  const roomActions = roomAnimations.actions;

  useEffect(() => {
    roomActions.Animation.play();
  },[roomActions]);

  useEffect(() => {
    let randomTimer;
    function playRandomAnimation(){
      const min = 5;
      const max = 15;
      const rand = Math.floor(Math.random() * (max - min + 1) + min);
      propActions.Animation.stop();
      propActions.Animation.play();

      randomTimer = setTimeout(playRandomAnimation, rand * 1000);
    }

    if(play) {
      propActions.Animation.setLoop(LoopOnce);
      playRandomAnimation();
    }
    return () => {
      randomTimer && clearTimeout(randomTimer)
    }
  }, [propActions, play]);


  useEffect(()=>{
    // if(gltfRoom.materials['Mat_Room']) {
    //   gltfRoom.materials['Mat_Room'].aoMap = texture;
    //   gltfRoom.materials['Mat_Room'].aoMapIntensity = 2;
    //
    //   console.log(gltfRoom.scene.children[0].children[5].children[0].geometry) //eslint-disable-line
    //   const geometry = gltfRoom.scene.children[0].children[5].children[0].geometry;
    //   geometry.setAttribute('uv2',geometry.attributes.uv);
    //   geometry.setAttribute('uv1',geometry.attributes.uv);
    //
    //   const geometry2 = gltfRoom.scene.children[0].children[5].children[1].geometry;
    //   geometry2.setAttribute('uv2',geometry2.attributes.uv);
    //   console.log(gltfRoom.scene.children[0].children[5].children[0]) //eslint-disable-line
    // }
    if(yPos) {
      gltfProps.scene.position.y = yPos;
      gltfRoom.scene.position.y = yPos;
    }
  },[yPos,  gltfProps.scene.position, gltfRoom.scene.position, gltfRoom.scene.children])

  useEffect(()=> {
    const propsToShow = [];
    const propsToHide = [];

    gltfProps.scene.castShadow = true;
    gltfProps.scene.receiveShadow = true;

    for (const [key, value] of Object.entries(gltfProps.nodes)) {
      if (value.type === 'Mesh') {
        gltfProps.nodes[key].castShadow = true;
        gltfProps.nodes[key].receiveShadow = true;
      }
      const propName = Object.keys(skillPropsMap).find(keyTwo => skillPropsMap[keyTwo] === key);

      if(skillPropsArray.find(item => item === key)) {
        if (objects.find(item => item === propName)) {
          if(!gltfProps.nodes[key].visible) {
            gltfProps.nodes[key].visible = true;
            propsToShow.push(gltfProps.nodes[key]);
          } else {
            gltfProps.nodes[key].visible = true;
          }
        } else {
          if(!initialised) {
            gltfProps.nodes[key].visible = false;
            setInitialised(true);
          } else {
            if(gltfProps.nodes[key].visible) {
              propsToHide.push(gltfProps.nodes[key]);
            }
          }
        }
      }
    }

    setShowProps(propsToShow);
    setHideProps(propsToHide);

  }, [objects, gltfProps.nodes, gltfProps.scene, initialised]);

  usePopUpEffect(showProps);
  usePopOutEffect(hideProps);

  return (
    <>
      <primitive object={gltfRoom.scene} ref={room} />
      <primitive object={gltfProps.scene} ref={props} />
    </>
  )
}

export default RoomModel
