import {PerspectiveCamera, OrbitControls} from "@react-three/drei";
import {useControl} from "react-three-gui";
import React, {useRef} from "react";

function Camera() {
  const x = useControl('x', { type: 'number', value: -7, min: 0, max: 70, group: "CAMERA"});
  const y = useControl('y', { type: 'number', value: 3, min: 0, max: 70, group: "CAMERA" });
  const z = useControl('z', { type: 'number', value: 7, min: 0, max: 70, group: "CAMERA" });
  const camera = useRef()

  return (
    <>
      <PerspectiveCamera
        makeDefault
        far={50}
        near={0.01}
        position={[x, y, z]}
        rotation={[-25, -45, 0.0]}
        zoom={0.9}
        scale={[1, 1, 1]}
        ref={camera}
      />
      <OrbitControls
        camera={camera.current}
        minAzimuthAngle={-Math.PI/2}
        maxAzimuthAngle={0}
        minPolarAngle={0}
        maxPolarAngle={Math.PI/2}
        minDistance={2}
        maxDistance={15}
      />
    </>
  )
}

export default Camera;
