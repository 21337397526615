import React from 'react';
import styles from './IconButton.module.scss';
import cn from 'classnames';

import back from "../../assets/images/Icon_Arrow-back.svg"
import close from "../../assets/images/Icon_Cross.svg"

function IconButton({ type, click }) {


  return (
    <div className={cn(styles.container)} onClick={click}>
      {type === "close" && <img src={close} alt={''} />}
      {type === "back" && <img src={back} alt={''} />}
    </div>
  )
};

export default IconButton;
