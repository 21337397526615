import React, {useEffect, useState} from 'react';
import styles from './WorkAreaDetail.module.scss';
import workareaContent from "../../content/workAreas";
import useScoresByWorkArea from "../../hooks/useScoresByWorkArea";
import ctaIconClosed from "../../assets/images/Icon_Arrow-down.svg";
import ctaIconOpen from "../../assets/images/Icon_Arrow-up.svg";
import DarkStar from "../../assets/images/Star_Dark_Score.svg";
import YellowStar from "../../assets/images/Star_Yellow_Score.svg";

import cn from 'classnames';
import uiLabels from "../../utils/uiLabels";

function WorkAreaDetail({ workAreaKey, skills }) {

  const [workArea, setWorkArea] = useState({});
  const [openDetails, setOpenDetails] = useState(true);

  const scores = useScoresByWorkArea(workAreaKey, skills);
  useEffect(()=> {
    const area = workareaContent.find(item => (item.key === workAreaKey));
    setWorkArea(area?.content);

  },[workAreaKey]);

  function ScorePanel({scores}) {
    return (
      <ul>
        {scores.map(score => (
          <li key={score.label} className={cn(styles.scoreRow, {[styles.selected]: score.selected})} >
            <span>{score.label}</span>
            <ul className={styles.stars} >
              <ScoreStar key={0} score={score} number={0}/>
              <ScoreStar key={1} score={score} number={1}/>
              <ScoreStar key={2} score={score} number={2}/>
              <ScoreStar key={3} score={score} number={3}/>
            </ul>
          </li>
        ))}
      </ul>
    )
  }

  function ScoreStar({score, number}){
    return (
      <li className={styles.star}>
        {score.selected && <img alt={''} src={YellowStar} className={cn({[styles.selected]: score.score > number})}/>}
        {!score.selected && <img alt={''} src={DarkStar} className={cn({[styles.selected]: score.score > number})}/>}
      </li>
    );
  }

  function renderLinks(content) {
    if(!content) return "";

    const rows = content.split('\n');
    return rows.map((row, index) => {
      const fields = row.split(',');

      return (
        <p key={index}><a target={'_blank'} rel="noreferrer" href={fields[1]}>{fields[0]}</a></p>
      );
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.page}>
        <h1>{workArea?.title}</h1>
        <div className={styles.cta} onClick={()=>setOpenDetails(!openDetails)} >
          <span>{uiLabels('area.stars')}</span>
          {!openDetails && <img src={ctaIconClosed} alt={''} onClick={()=>setOpenDetails(true)}/>}
          {openDetails && <img src={ctaIconOpen} alt={''}  onClick={()=>setOpenDetails(false)}/>}
        </div>
        {openDetails && (
          <div className={styles.checkScoresPanel}>
            <ScorePanel scores={scores}/>
          </div>
        )}
        <p className={styles.spacer}>{workArea?.intro}</p>
        <h1 className={styles.spacer}>{uiLabels('area.links2')}</h1>
        {!!workArea && renderLinks(workArea.links_2)}
        <h1 className={styles.spacer}>{uiLabels('area.links3')}</h1>
        {!!workArea && renderLinks(workArea.links_3)}
      </div>
    </div>
  )
};

export default WorkAreaDetail;
